.login-dark {
  height: 100vh;
  background: #475d62 url("/img/darkbg.jpg");
  background-size: cover;
  position: relative;

  form {
    max-width: 320px;
    width: 90%;
    // background-color: #1e2833;
    background-color: rgba(30, 40, 51, 0.98);
    padding: 40px;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);

    .form-control {
      background: none;
      border: none;
      border-bottom: 1px solid #434a52;
      border-radius: 0;
      box-shadow: none;
      outline: none;
      color: inherit;
      position: relative;
      padding-right:25px;
    }

    .btn-primary {
      background: #214a80;
      border: none;
      border-radius: 4px;
      padding: 11px;
      box-shadow: none;
      margin-top: 26px;
      text-shadow: none;
      outline: none;

      &:active {
        transform: translateY(1px);
      }
      &:hover,
      &:active {
        background: #214a80;
        outline: none;
      }
      span{
        position: relative;
        top: -5px;
        //color: #FFF;
      }      

    }

    .forgot {
      display: block;
      text-align: center;
      font-size: 12px;
      color: #6f7a85;
      opacity: 0.9;
      text-decoration: none;

      &:hover,
      &:active {
        opacity: 1;
        text-decoration: none;
      }
    }
  }

  .illustration {
    text-align: center;
    font-size: 15px;
    color: #2980ef;
    position: absolute;
    right: 40px;
    bottom:132px;
  }
}

.loading-image {
  width: 100%;
  text-align: center;
}

.info-btn {
  margin-right: 5px;
}

.check-group {
  display: flex;
  #checkbox {
    margin-right: 1rem;
  }
  .checkbox-label {
    cursor: pointer;
  }
}

.all-cards {
  width: 100%;
  position: relative;
  
  .ant-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .todoCard {
    z-index: 9;
    margin: 25px 25px;
    @media(max-width:767px){
      width: 100%;
    }
  }
  .dashed-line {
    width: 100%;
    margin: 5px 0;
    height: 5px;
    background: repeating-linear-gradient(
      to right,
      #eed 0,
      #eee 13px,
      transparent 13px,
      transparent 18px
    );
    z-index: 7;
    position: absolute;
    top: 50%;
  }
}
