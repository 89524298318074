.login-dark {
  height: 100vh;
  background: #475d62 url("/img/darkbg.jpg");
  background-size: cover;
  position: relative;
}
.login-dark form {
  max-width: 320px;
  width: 90%;
  background-color: rgba(30, 40, 51, 0.98);
  padding: 40px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}
.login-dark form .form-control {
  background: none;
  border: none;
  border-bottom: 1px solid #434a52;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  position: relative;
  padding-right: 25px;
}
.login-dark form .btn-primary {
  background: #214a80;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 26px;
  text-shadow: none;
  outline: none;
}
.login-dark form .btn-primary:active {
  transform: translateY(1px);
}
.login-dark form .btn-primary:hover, .login-dark form .btn-primary:active {
  background: #214a80;
  outline: none;
}
.login-dark form .btn-primary span {
  position: relative;
  top: -5px;
}
.login-dark form .forgot {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}
.login-dark form .forgot:hover, .login-dark form .forgot:active {
  opacity: 1;
  text-decoration: none;
}
.login-dark .illustration {
  text-align: center;
  font-size: 15px;
  color: #2980ef;
  position: absolute;
  right: 40px;
  bottom: 132px;
}

.loading-image {
  width: 100%;
  text-align: center;
}

.info-btn {
  margin-right: 5px;
}

.check-group {
  display: flex;
}
.check-group #checkbox {
  margin-right: 1rem;
}
.check-group .checkbox-label {
  cursor: pointer;
}

.all-cards {
  width: 100%;
  position: relative;
}
.all-cards .ant-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.all-cards .todoCard {
  z-index: 9;
  margin: 25px 25px;
}
@media (max-width: 767px) {
  .all-cards .todoCard {
    width: 100%;
  }
}
.all-cards .dashed-line {
  width: 100%;
  margin: 5px 0;
  height: 5px;
  background: repeating-linear-gradient(to right, #eed 0, #eee 13px, transparent 13px, transparent 18px);
  z-index: 7;
  position: absolute;
  top: 50%;
}

.ant-carousel .slick-dots li button {
  background: rgb(67, 56, 56);
}
.ant-carousel .slick-dots li.slick-active button {
  background: #000;
}

.order-status-approve {
  background-color: #a180da;
}

.order-status-active {
  background-color: #5b7dff;
}

.order-status-complete {
  background-color: #5fc27e;
}

.order-status-tobereturned {
  background-color: #fcc100;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.gutter-box {
  padding: 8px 0;
}

.navbar-top .sidebar-icon {
  cursor: pointer;
}